exports.onInitialClientRender = () => {
  setTimeout(function() {
    document.getElementById("___loader").style.display = "none"
    document.body.style.overflowY = "visible"
  }, 1000)
  document.getElementById("___gatsby").style.display = "block"
  document.body.style.overflowY = "hidden"
}

// const React = require("react")
// const CookieBot = require("react-cookiebot/lib/CookieBot")
// const domainGroupId = "60763419-b7f7-47c5-b8a3-90ae55ff017a"

// exports.wrapPageElement = ({ element }) => {
//   return (
//     <div>
//       <CookieBot domainGroupId={domainGroupId} />
//       {element}
//     </div>
//   )
// }
